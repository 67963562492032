import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { Box, useMediaQuery } from '@mui/material'
import BranchConfig from './components/branchConfig/BranchConfig'
import AutoNumbers from './components/autoNumbers/AutoNumbers'
import ReportConfig from './components/reportConfig/ReportConfig'
import SyncConfig from './components/syncConfig/syncConfig'
import DiscountsGrid from './components/discounts/DiscountsGrid'
import Div from '@jumbo/shared/Div'
import { useJumboTheme } from '@jumbo/hooks'

function BranchSettings() {
    const navigate = useNavigate()
    const { tabTitle } = useParams()
    const [value, setValue] = React.useState(tabTitle ? tabTitle : 'syncconfig')
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const onTabSelectionChanged = (e, newValue) => {
        setValue(newValue)
        navigate(`/store/settings/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} sx={{ mt: 1 }} variant={'h4'}>
                        Branch Settings
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <Tabs
                    value={value}
                    onChange={onTabSelectionChanged}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                    sx={{
                        bgcolor: 'background.default',
                        '.MuiTabs-scrollButtons': {
                            // Styling for scroll buttons
                            '&.Mui-disabled': {
                                opacity: 0.3, // Ensure disabled buttons are visible but faded
                            },
                        },
                    }}>
                    <Tab
                        label='Sync Config'
                        value='syncconfig'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Branch Config'
                        value='branchconfig'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Auto Numbers'
                        value='autonumbers'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Discounts'
                        value='discounts'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                </Tabs>
                <Div sx={{ mt: '12px', ml: '-5px', mr: '-5px' }}>
                    {value === 'syncconfig' && (
                        <Box>
                            <SyncConfig />
                        </Box>
                    )}
                </Div>
                <Div sx={{ mt: '12px', ml: '-5px', mr: '-5px' }}>
                    {value === 'branchconfig' && (
                        <Box>
                            <BranchConfig />
                        </Box>
                    )}
                </Div>
                <Div sx={{ mt: '12px', ml: '-5px', mr: '-5px' }}>
                    {value === 'reportconfig' && (
                        <Box>
                            <ReportConfig />
                        </Box>
                    )}
                </Div>
                <Div sx={{ mt: '12px', ml: '-5px', mr: '-5px' }}>
                    {value === 'autonumbers' && (
                        <Box>
                            <AutoNumbers />
                        </Box>
                    )}
                </Div>
                <Div sx={{ mt: '12px', ml: '-5px', mr: '-5px' }}>
                    {value === 'discounts' && (
                        <Box>
                            <DiscountsGrid />
                        </Box>
                    )}
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default BranchSettings
